<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewSalespersonSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-md"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-add-new-salesperson-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          <span v-if="salespersonItem.added_to_list">Remove</span>
          <span v-else>Adding</span>
          Salesperson to List
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <div class="font-weight-bolder">
            <h4>{{ salespersonItem.name }}</h4>
            <!--            <b-button-->
            <!--              v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
            <!--              variant="outline-info"-->
            <!--              pill-->
            <!--              size="sm"-->
            <!--            >-->
            <!--              View Performance-->
            <!--            </b-button>-->
          </div>
          <b-list-group
            flush
            class="mt-1 rounded-0"
          >
            <template v-for="(item, key, index) in salespersonItem">
              <b-list-group-item
                v-if="key !== 'salesperson_id' && key !== 'id' && key !== 'name'
                  && key !== 'landing_page_uuid' && key !== 'added_to_list'"
                :key="index"
                class="bg-transparent"
              >
                <div class="d-flex justify-content-between">
                  <span
                    class="ml-1"
                    v-text="key + ' :'"
                  />
                  <span
                    class="ml-1"
                    v-text="item"
                  />
                </div>
              </b-list-group-item>
            </template>

          </b-list-group>
          <div
            v-if="salespersonItem.added_to_list"
            class="px-2 mt-1 font-weight-bolder"
          >
            <p>Removing will randomly set his/her leads to other salesperson in the list. Are you sure?</p>
          </div>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-if="salespersonItem.added_to_list"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              class="mr-2"
              type="submit"
              :disabled="disableBtn"
            >
              Remove
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="mr-2"
              type="submit"
              :disabled="disableBtn"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BButton, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import {
  required, alphaNum, integer, email,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import Ripple from 'vue-ripple-directive'
import genders from '@/@fake-db/data/other/genders'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BButton,
    BListGroup,
    BListGroupItem,

    // Form Validation
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewSalespersonSidebarActive',
    event: 'update:is-add-new-salesperson-sidebar-active',
  },
  props: {
    isAddNewSalespersonSidebarActive: {
      type: Boolean,
      required: true,
    },
    salespersonItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      alphaNum,
      integer,
      email,
      genders,
      disableBtn: false,
    }
  },
  setup() {
    const toast = useToast()

    return {
      toast,
    }
  },
  methods: {
    async onSubmit() {
      this.disableBtn = true
      this.salespersonItem.landing_page_uuid = this.$route.params.uuid
      store.dispatch('menu-lead/addSalespersonList', this.salespersonItem)
        .then(response => {
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-salesperson-sidebar-active', false)
          this.toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Successfull!',
              icon: 'CheckIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
          this.disableBtn = false
          delete this.salespersonItem.landing_page_uuid
        })
        .catch(error => {
          if (error.response !== undefined){
            this.toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `${error.response.status} Error has occured`,
                icon: 'XIcon',
                variant: 'danger',
                text: error.response.data.message,
              },
            })
            this.disableBtn = false
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
