<template>
  <div v-if="landingPageUuid">
    <salesperson-list
      :salesperson-item="salesPersonItem"
      :is-add-new-salesperson-sidebar-active.sync="isAddNewUserSidebarActive"
      @refetch-data="refetchData"
    />
    <b-row class="match-height">
      <b-col
        cols="12"
        class="mx-0 px-0"
      >
        <b-card
          :sub-title="selfData.role.code === 'su' || selfData.role.code === 'sa' ? 'Add more salesperson to lead list' : ''"
          class="d-flex justify-content-center"
        >
          <b-overlay
            :show="totalSalespersons === null ? true : false"
            variant="light"
            :opacity="0.7"
            blur="2px"
            rounded="sm"
          >
            <b-table
              ref="refSalespersonListTable"
              class="position-relative"
              :items="getSalesperson"
              responsive
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="sortBy"
              show-empty
              empty-text="No matching records found"
              :sort-desc.sync="isSortDirDesc"
            >

              <!-- Column: In Lead List -->
              <template #cell(lead_list)="data">
                <b-media vertical-align="center">
                  <span class="text-center">
                    {{ data.item.salesperson_lists.length }} / {{ this.totalLandingPage }}
                  </span>
                </b-media>
              </template>
              <!-- Column: Conversion Rate -->
              <template #cell(conv_rate)="data">
                <b-media vertical-align="center">
                  <span class="text-center">
                    {{ (data.item.salesperson_deal_leads.length / data.item.salesperson_histories.length)*100 | formatNumber }} %
                  </span>
                </b-media>
              </template>
              <!-- Column: Total Handle -->
              <template #cell(total_handle)="data">
                <b-media vertical-align="center">
                  <span class="text-center">
                    {{ data.item.salesperson_histories.length }}
                  </span>
                </b-media>
              </template>
              <!-- Column: total New -->
              <template #cell(total_new)="data">
                <b-media vertical-align="center">
                  <span class="text-center">
                    {{ data.item.salesperson_new_leads.length }}
                  </span>
                </b-media>
              </template>
              <!-- Column: total On Process -->
              <template #cell(total_process)="data">
                <b-media vertical-align="center">
                  <span class="text-center">
                    {{ data.item.salesperson_on_process_leads.length }}
                  </span>
                </b-media>
              </template>
              <!-- Column: total Visit -->
              <template #cell(total_visit)="data">
                <b-media vertical-align="center">
                  <span class="text-center">
                    {{ data.item.salesperson_visit_leads.length }}
                  </span>
                </b-media>
              </template>
              <!-- Column: total Deal -->
              <template #cell(total_deal)="data">
                <b-media vertical-align="center">
                  <span class="text-center">
                    {{ data.item.salesperson_deal_leads.length }}
                  </span>
                </b-media>
              </template>
              <!-- Column: total Fail -->
              <template #cell(total_fail)="data">
                <b-media vertical-align="center">
                  <span class="text-center">
                    {{ data.item.salesperson_fail_leads.length }}
                  </span>
                </b-media>
              </template>
              <!-- Column: Total Handle -->
              <template #cell(last_added)="data">
                <b-media vertical-align="center">
                  <span
                    v-if="data.item.salesperson_lists_of_landing_page.length > 0"
                    class="text-center"
                  >
                    {{ data.item.salesperson_lists_of_landing_page[data.item.salesperson_lists_of_landing_page.length - 1].updated_at | moment('D MMM YYYY') }}
                  </span>
                  <span
                    v-else
                    class="text-center"
                  >
                    -
                  </span>
                </b-media>
              </template>
              <!-- Column: User -->
              <template #cell(user)="data">
                <b-media vertical-align="center">
                  <template #aside>
                    <b-avatar
                      size="32"
                      :src="data.item.avatar"
                      :text="avatarText(data.item.name)"
                      :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                    />
                  </template>
                  <b-link class="font-weight-bold d-block text-nowrap">
                    {{ data.item.name }}
                  </b-link>
                  <small class="text-muted">{{ data.item.email }}</small>
                </b-media>
              </template>
              <!-- Column: Actions -->
              <template
                v-if="selfData.role.code === 'su' || selfData.role.code === 'sa'"
                #cell(actions)="data"
              >
                <b-button
                  v-if="data.item.salesperson_lists_of_landing_page.length > 0 && data.item.salesperson_lists_of_landing_page[data.item.salesperson_lists_of_landing_page.length - 1].active == true"
                  v-ripple.300="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  pill
                  size="sm"
                  @click="() => {
                    isAddNewUserSidebarActive = true;
                    salesPersonItem.salesperson_id = data.item.id;
                    salesPersonItem.added_to_list = (data.item.salesperson_lists_of_landing_page.length > 0
                      && data.item.salesperson_lists_of_landing_page[data.item.salesperson_lists_of_landing_page.length - 1].active == true)
                      ? true
                      : false;
                    salesPersonItem.name = data.item.name;
                    salesPersonItem.email = data.item.email;
                    salesPersonItem.phone = data.item.phone;
                    salesPersonItem.total_handle = data.item.salesperson_histories.length;
                    salesPersonItem.conv_rate = (data.item.salesperson_deal_leads.length / data.item.salesperson_histories.length)*100;
                    salesPersonItem.new = data.item.salesperson_new_leads.length;
                    salesPersonItem.deal = data.item.salesperson_deal_leads.length;
                    salesPersonItem.fail = data.item.salesperson_fail_leads.length;
                  }"
                >
                  Remove
                </b-button>
                <b-button
                  v-else
                  v-ripple.300="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-info"
                  pill
                  size="sm"
                  @click="() => {
                    isAddNewUserSidebarActive = true;
                    salesPersonItem.salesperson_id = data.item.id;
                    salesPersonItem.added_to_list = (data.item.salesperson_lists_of_landing_page.length > 0
                      && data.item.salesperson_lists_of_landing_page[data.item.salesperson_lists_of_landing_page.length - 1].active == true)
                      ? true
                      : false;
                    salesPersonItem.name = data.item.name;
                    salesPersonItem.email = data.item.email;
                    salesPersonItem.phone = data.item.phone;
                    salesPersonItem.total_handle = data.item.salesperson_histories.length;
                    salesPersonItem.conv_rate = (data.item.salesperson_deal_leads.length / data.item.salesperson_histories.length)*100;
                    salesPersonItem.new = data.item.salesperson_new_leads.length;
                    salesPersonItem.deal = data.item.salesperson_deal_leads.length;
                    salesPersonItem.fail = data.item.salesperson_fail_leads.length;
                  }"
                >
                  Add
                </b-button>
              </template>

            </b-table>
          </b-overlay>
          <div
            v-if="dataMeta.of > perPage"
            class="mx-2 mb-2"
          >
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalSalespersons"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <!--/ collapse -->
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BPagination, BLink, BMedia, BAvatar, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useSalespersonsList from './components/useSalespersonsList'
import userStoreModule from '../../user/userStoreModule'
import SalespersonList from './components/SalespersonList.vue'

const numeral = require('numeral')

export default {
  components: {
    SalespersonList,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BPagination,
    BLink,
    BMedia,
    BAvatar,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  filters: {
    formatNumber(value) {
      return numeral(value).format('0.00')
    },
  },
  props: {
    landingPageUuid: {
      type: String,
      required: true,
      default: '',
    },
    totalLandingPage: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selfData: {
        role: {
          code: '',
        },
      },
      currentSalespersons: [],
      salesPersonItem: {
        salesperson_id: 0,
        name: 'Name',
        email: 'email',
        phone: '08xxxxxxx',
        total_handle: 64,
        conv_rate: 32,
        new: 12,
        deal: 12,
        fail: 4,
      },
    }
  },
  created() {
    this.fetchSalespersons()
    this.getSelf()
  },
  methods: {
    async getSelf() {
      await store.dispatch('app-user/getSelf')
        .then(response => {
          this.selfData = response.data
          this.selfData.username = response.data.name.replace(/\s+/g, '-').toLowerCase()
        })
    },
    async fetchSalespersons() {
      if (this.$route.params.uuid !== undefined){
        await store.dispatch('menu-lead/getSalespersonByLandingPage', { uuid: this.$route.params.uuid })
          .then(response => {
            this.currentSalespersons = response.data.data
          }).catch(error => {
            if (error.response.status === 403) {
              this.$router.push({ name: 'error-404' })
            }
          })
      }
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    const isAddNewUserSidebarActive = ref(false)

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      getSalesperson,
      tableColumns,
      perPage,
      currentPage,
      totalSalespersons,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSalespersonListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,

      // Extra Filters
    } = useSalespersonsList()

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      getSalesperson,
      tableColumns,
      perPage,
      currentPage,
      totalSalespersons,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSalespersonListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,

      // Extra Filters
    }
  },
}
</script>
