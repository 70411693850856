<template>
  <div>
    <b-overlay
      :show="bulkImportShow"
      variant="light"
      :opacity="0.7"
      blur="2px"
      rounded="sm"
    >
      <b-row>
        <b-col
          class="filter mb-2"
          cols="12"
          lg="3"
          xl="2"
        >
          <div class="filter__sidebar ml-2">
            <!-- Table Filter -->
            <div>
              <!-- Status -->
              <div class="mb-2">
                <label>Status</label>
                <v-select
                  v-model="statusFilter"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOptions"
                  class="w-100"
                  :clearable="false"
                  :reduce="val => val.value"
                />
              </div>
              <!-- Salesperson -->
              <div
                v-if="$store.state.currentUser.role.code === 'su'"
                class="mb-2"
              >
                <label>Salesperson</label>
                <v-select
                  v-model="salespersonFilter"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="salespersonHaveLeadOptions"
                  class="w-100"
                  :clearable="false"
                  :reduce="val => val.value"
                />
              </div>
              <!-- Origin -->
              <div class="mb-2">
                <label>Origin</label>
                <v-select
                  v-model="channelFilter"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="channelOptions"
                  class="w-100"
                  :clearable="false"
                  :reduce="val => val.value"
                >
                  <template #selected-option="value">
                    {{ setSelectedOption(channelOptions, value) }}
                  </template>
                </v-select>
              </div>
              <!-- Date Submitted -->
              <div
                cols="12"
                sm
                class="mb-2"
              >
                <label>Date Submitted</label>
                <b-input-group>
                  <flat-pickr
                    v-model="dateFilter"
                    class="form-control"
                    :config="{ dateFormat: 'd-m-Y', mode: 'range' }"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-danger"
                      class="px-1 py-0"
                      @click="clearDateFilter"
                    >
                      X
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </div>
            </div>
          </div>
        </b-col>

        <b-col
          class="table mb-2"
          cols="12"
          lg="9"
          xl="10"
        >
          <b-card
            no-body
            class="pb-2"
          >
            <div class="table__filter mb-2">
              <div class="table__filter-left">
                <!-- Delete -->
                <div class="mx-2">
                  <b-button
                    variant="outline-danger"
                    :disabled="selectRow.length === 0"
                    @click="deleteRow"
                  >
                    <span v-if="windowWidth > 960 ">
                      <feather-icon icon="Trash2Icon" /> Delete
                    </span>
                    <span v-else>
                      <feather-icon icon="Trash2Icon" />
                    </span>
                  </b-button>
                </div>
                <!-- Search -->
                <div class="mr-2">
                  <b-form-input
                    v-model="searchQuery"
                    placeholder="Search..."
                  />
                </div>
              </div>

              <div class="table__filter-right align-items-baseline">
                <div class="table__filter-right-menu">
                  <!-- Hotlead -->
                  <div class="hotlead ml-1">
                    <b-form-checkbox
                      v-model="hotleadFilter"
                      class="hotlead-btn d-flex align-items-center"
                      checked="false"
                      name="hotlead-btn"
                      switch
                      inline
                    >
                      <span style="font-size: 1rem">Hotlead</span>
                    </b-form-checkbox>
                  </div>
                  <!-- Bulk Input -->
                  <div class="import ml-1">
                    <b-link
                      v-b-modal.modal-header-import
                      class="import-link"
                      :disabled="importBtn"
                    >
                      <span>
                        <feather-icon
                          size="14"
                          icon="FolderPlusIcon"
                        />
                        Bulk Input
                      </span>
                    </b-link>
                  </div>
                  <!-- Download Leads -->
                  <div class="export ml-1">
                    <b-link
                      v-b-modal.modal-export
                      class="export-link"
                      :disabled="exportBtn"
                    >
                      <span>
                        <feather-icon
                          size="14"
                          icon="DownloadIcon"
                        />
                        Download Leads
                      </span>
                    </b-link>
                  </div>
                </div>

                <!-- Import Modal -->
                <div>
                  <b-modal
                    id="modal-header-import"
                    title="Bulk Input (Import)"
                    ok-title="Verify File"
                    ok-only
                    no-close-on-backdrop
                    centered
                    @ok="parseCSV"
                  >
                    <b-form @submit.prevent="handleSubmit(parseCSV)">
                      <b-form-group class="mt-1">
                        <label for="file_import">Choose a CSV file here</label>
                        <b-form-file
                          id="file_import"
                          v-model="importData.file_import"
                          accept=".csv, .xls"
                        />
                      </b-form-group>
                    </b-form>
                  </b-modal>
                  <b-modal
                    id="modal-submit-import"
                    title="Bulk Input (Import)"
                    ok-title="Import"
                    ok-only
                    no-close-on-backdrop
                    centered
                    @ok="submitImport"
                    @hidden="resetImportData"
                  >
                    <h6 class="pt-2 font-weight-bolder">
                      Match the appropriate column detected from your file to the lead's
                    </h6>
                    <b-form @submit.prevent="handleSubmit(submitImport)">
                      <div class="table-submit-import">
                        <table>
                          <tr>
                            <th
                              v-for="header in importData.data_header"
                              :key="header.value"
                            >
                              {{ header.label }}
                            </th>
                          </tr>
                          <tr>
                            <td
                              v-for="(items, index) in importData.data_header"
                              :key="index"
                              ref="form_select"
                            >
                              <!-- <v-select
                                id="form_label"
                                ref="form_label"
                                v-model="importData.form_label[index]"
                                class="w-100"
                                label="label"
                                :options="importData.option_label"
                                :reduce="value => `${value.value}-${items.value}`"
                              /> -->
                              <b-form-select
                                id="form_label"
                                ref="form_label"
                                v-model="importData.form_label[index]"
                                class="w-100"
                              >
                                <!-- v-on:change="onFormSelect($event, index)" -->
                                <template #first>
                                  <b-form-select-option :value="null">
                                    ---
                                  </b-form-select-option>
                                </template>
                                <b-form-select-option
                                  v-for="(option, index) in importData.option_label"
                                  :key="index"
                                  :value="`${option.value}-${items.value}`"
                                >
                                  {{ option.text }}
                                </b-form-select-option>
                              </b-form-select>
                            </td>
                          </tr>
                          <tr
                            v-for="(items, indexRecord) in importData.data_records"
                            :key="indexRecord"
                            class="table-data-record"
                          >
                            <td
                              v-for="(items, indexItems) in importData.data_records[indexRecord]"
                              :key="indexItems"
                            >
                              {{ items }}
                            </td>
                          </tr>
                        </table>
                      </div>
                      <h6 class="pb-2">
                        Showing the first {{ importData.file_count }} rows. Total {{ importData.file_total }} rows detected in <span class="font-weight-bolder">{{ importData.file_name }}</span>
                      </h6>
                      <b-form-group label="Assignment Method:">
                        <b-form-radio-group
                          v-model="importData.import_assigned_model"
                          :options="importAssignedOptions"
                          name="radios-stacked"
                          stacked
                        />
                      </b-form-group>
                      <div v-if="importData.import_assigned_model === 'specific'">
                        <label>Salesperson</label>
                        <v-select
                          v-model="importData.salesperson_assigned"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="assignedSalespersonOptions"
                          class="w-100"
                          :clearable="false"
                          :reduce="val => val.value"
                        />
                      </div>
                    </b-form>
                  </b-modal>
                </div>

                <!-- No Entries Export Modal -->
                <template v-if="totalForms <= 0">
                  <b-modal
                    id="modal-export"
                    title="No Leads found"
                    centered
                    ok-only
                    size="xs"
                  />
                </template>

                <!-- Export Modal -->
                <template v-else>
                  <b-modal
                    id="modal-export"
                    title="Download"
                    ok-title="Download"
                    ok-only
                    centered
                    no-close-on-backdrop
                    @ok="submitExport"
                  >
                    <b-form @submit.prevent="handleSubmit(submitExport)">
                      <b-form-group class="mt-1">
                        <label>Date range</label>
                        <flat-pickr
                          v-model="exportData.range_date"
                          class="form-control"
                          style="z-index: 1"
                          :config="{ mode: 'range', dateFormat: 'd-m-Y'}"
                        />
                      </b-form-group>
                    </b-form>
                  </b-modal>
                </template>

                <!-- Filter Btn -->
                <div class="ml-2 filter-collapse-btn d-none">
                  <b-button
                    v-b-toggle.table-filter
                    class="btn-icon"
                    block
                    variant="outline-secondary"
                  >
                    <feather-icon icon="FilterIcon" />
                  </b-button>
                </div>
                <b-sidebar
                  id="table-filter"
                  title="Filter"
                  bg-variant="white"
                  right
                  shadow
                  backdrop
                >
                  <div class="table-filter m-2">
                    <b-row>
                      <b-col
                        class="mb-xl-0 mb-2"
                        cols="12"
                      >
                        <label>Status</label>
                        <v-select
                          v-model="statusFilter"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="statusOptions"
                          class="w-100"
                          :clearable="false"
                          :reduce="val => val.value"
                        />
                      </b-col>
                      <b-col
                        class="mb-xl-0 mb-2"
                        cols="12"
                      >
                        <label>Salesperson</label>
                        <v-select
                          v-model="salespersonFilter"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="salespersonOptions"
                          class="w-100"
                          :clearable="false"
                          :reduce="val => val.value"
                        />
                      </b-col>
                      <b-col
                        class="mb-xl-0 mb-2"
                        cols="12"
                      >
                        <label>Origin</label>
                        <v-select
                          v-model="channelFilter"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="channelOptions"
                          class="w-100"
                          :clearable="false"
                          :reduce="val => val.value"
                        >
                          <template #selected-option="value">
                            {{ setSelectedOption(channelOptions, value) }}
                          </template>
                        </v-select>
                      </b-col>
                      <b-col
                        class="mb-xl-0 mb-2"
                        cols="12"
                      >
                        <label>Date Submitted</label>
                        <b-input-group>
                          <flat-pickr
                            v-model="dateFilter"
                            class="form-control"
                            :config="{ dateFormat: 'd-m-Y', mode: 'range' }"
                          />
                          <b-input-group-append>
                            <b-button
                              variant="outline-danger"
                              class="px-1 py-0"
                              @click="clearDateFilter"
                            >
                              X
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-col>
                      <b-col
                        cols="3"
                        class="mb-xl-0 mb-2 d-flex flex-column hotlead"
                      >
                        <label>Hotlead</label>
                        <b-form-checkbox
                          v-model="hotleadFilter"
                          class="hotlead-btn mr-0 d-flex align-items-center"
                          checked="false"
                          name="hotlead-btn"
                          switch
                          inline
                        />
                      </b-col>
                      <!-- Tool -->
                      <b-col
                        cols="9"
                        class="mb-xl-0 mb-2"
                      >
                        <label>Tool</label>
                        <div>
                          <div class="import flex-column">
                            <b-link
                              v-b-modal.modal-header-import
                              class="import-link"
                              :disabled="importBtn"
                            >
                              <span>
                                <feather-icon
                                  size="14"
                                  icon="FolderPlusIcon"
                                />
                                Bulk Input
                              </span>
                            </b-link>
                          </div>
                          <div class="export flex-column">
                            <b-link
                              v-b-modal.modal-export
                              class="export-link"
                              :disabled="exportBtn"
                            >
                              <span>
                                <feather-icon
                                  size="14"
                                  icon="DownloadIcon"
                                />
                                Download Leads
                              </span>
                            </b-link>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-sidebar>
              </div>
            </div>

            <!-- Table -->
            <b-overlay
              :show="totalForms == null ? true : false"
              variant="light"
              :opacity="0.7"
              blur="2px"
              rounded="sm"
            >
              <b-table
                ref="refFormInputListTable"
                class="position-relative row-hand-cursor ml-2"
                :items="getFormResults"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No matching records found"
                style="width: auto;"
                :sort-desc.sync="isSortDirDesc"
                @row-clicked="rowClickHandler"
              >
                <!-- @row-clicked="rowClickHandler" -->

                <!-- Column: Slug -->
                <!--          <template v-for="val in tableKeys">-->
                <!--            <template #cell(val)="value">-->
                <!--              <div class="text-nowrap" :key="val">-->
                <!--                <span class="align-text-top">{{ value.item }}</span>-->
                <!--              </div>-->
                <!--            </template>-->
                <!--          </template>-->
                <!--          <template #cell(fullname)="data">-->
                <!--            <div class="text-nowrap">-->
                <!--              <b-link :to="{ name: 'leads-detail', params: { id: data.item.id, uuid: $route.params.uuid } }">-->
                <!--                {{ data.form_result.fullname }}-->
                <!--              </b-link>-->
                <!--            </div>-->
                <!--          </template>-->

                <template #cell(created_at)="data">
                  <div class="text-nowrap">
                    <span class="align-text-top">{{ data.item.created_at| moment('D MMM YYYY') }}</span>
                  </div>
                </template>

                <template #cell(actions)="data">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-modal.modal-preview
                    v-b-tooltip.hover.top="'Detail'"
                    variant="outline-primary"
                    class="btn-icon rounded-circle mx-1"
                    :to="{ name: 'leads-detail', params: { id: data.item.id, uuid: $route.params.uuid } }"
                  >
                    <feather-icon icon="FileTextIcon" />
                  </b-button>
                </template>

                <template #cell(selected)="data">
                  <div @click.stop>
                    <b-form-checkbox
                      ref="selectRow"
                      name="selectRow"
                      @change="rowSelected($event, data)"
                    />
                  </div>
                </template>
                <!-- Column: Actions -->

              </b-table>
            </b-overlay>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>

    <div
      v-if="dataMeta.of > perPage"
      class="mx-2 mb-2"
    >
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalForms"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @change="onPageChanged"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
            <template #page="{ page, active }">
              <b v-if="active">{{ page }}</b>
              <i v-else>{{ page }}</i>
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </div>

  <!--/ collapse -->
</template>

<script>
import store from '@/store'
import {
  BSidebar, BFormSelect, BFormSelectOption, BLink, BFormCheckbox, BCard,
  BTable, BRow, BCol, BForm, BFormRadioGroup, BFormGroup, BFormFile,
  BFormInput, BInputGroup, BInputGroupAppend, BPagination, BOverlay,
  BButton, BModal, VBModal, VBTooltip, VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import { onUnmounted } from '@vue/composition-api'
import useFormInputDetails from './useFormInputDetails'
import leadStoreModule from '../leadStoreModule'

export default {
  components: {
    BSidebar,
    vSelect,
    BCard,
    BTable,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormFile,
    BFormGroup,
    BFormRadioGroup,
    BInputGroup,
    BInputGroupAppend,
    BPagination,
    BOverlay,
    BButton,
    BModal,
    flatPickr,
    BFormCheckbox,
    BLink,
    BFormSelect,
    BFormSelectOption,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {
    landingPageUuid: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      windowWidth: 0,
      user_id: null,
      selectRow: [],
      formInputs: [],
      statusOptions: [],
      assignedSalespersonOptions: [],
      salespersonOptions: [
        { label: 'All', value: 0 },
      ],
      salespersonHaveLeadOptions: [
        { label: 'All', value: 0 },
      ],
      channelOptions: [
        { label: 'All', value: 'all' },
      ],
      importAssignedOptions: [
        { text: 'Distribute to all Salespersons', value: 'distributed' },
        { text: 'Assign to specific Salesperson', value: 'specific' },
      ],
      exportData: {
        lead_status_id: this.statusFilter,
        range_date: null,
      },
      importData: {
        landing_page_uuid: this.$route.params.uuid,
        salesperson_assigned: null,
        import_assigned_model: 'distributed',
        file_import: null,
        file_name: '',
        file_count: null,
        file_total: null,
        form: [],
        form_label: [],
        data_header: [],
        data_records: [],
        option_label: [],
        input: [],
      },
      exportBtn: false,
      importBtn: false,
      bulkImportShow: false,
    }
  },
  setup() {
    const toast = useToast()

    const FORM_APP_STORE_MODULE_NAME = 'menu-lead'
    // Register module
    if (!store.hasModule(FORM_APP_STORE_MODULE_NAME)) store.registerModule(FORM_APP_STORE_MODULE_NAME, leadStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FORM_APP_STORE_MODULE_NAME)) store.unregisterModule(FORM_APP_STORE_MODULE_NAME)
    })

    const {
      getFormResults,
      tableColumns,
      tableKeys,
      perPage,
      currentPage,
      totalForms,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFormInputListTable,
      refetchData,
      clearDateFilter,

      // Extra Filters
      statusFilter,
      salespersonFilter,
      channelFilter,
      hotleadFilter,
      dateFilter,
    } = useFormInputDetails()

    return {
      getFormResults,
      tableColumns,
      tableKeys,
      perPage,
      currentPage,
      totalForms,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFormInputListTable,
      refetchData,
      clearDateFilter,

      toast,

      // Extra Filters
      statusFilter,
      salespersonFilter,
      channelFilter,
      hotleadFilter,
      dateFilter,
    }
  },
  created(){
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    this.hotleadFilter = this.$store.state.hotlead
    this.fetchAllLeadStatuses()
    this.fetchAllMarketingChannels()
    this.fetchAllSalespersons()
    this.fetchAllSalespersonHaveLeads()
    this.getSelf()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  mounted(){
    // this.currentPage = this.$store.state.entriesPageDatatable
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth
    },
    getSelf() {
      store.dispatch('app-user/getSelf')
        .then(response => {
          this.user_id = response.data.id
        })
    },
    deleteRow() {
      this.$swal({
        title: 'Delete entry',
        text: 'Pemanently delete the selected entry?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('menu-lead/deleteLeads', {
            leads_id: this.selectRow,
            user_id: this.user_id,
          })
            .then(response => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              setTimeout(() => {
                this.$refs.refFormInputListTable.refresh()
              }, 2000)
            }).catch(error => {
              if (error.response !== undefined){
                this.toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error has occured',
                    icon: 'XIcon',
                    variant: 'danger',
                    text: error.response.data.message,
                  },
                })
                this.importBtn = false
              }
            })
        }
      })
    },
    rowSelected(event, data) {
      if (event) {
        this.selectRow.push(data.item.id)
        this.$refs.refFormInputListTable.selectRow(data.index)
      } else {
        this.selectRow.forEach((item, index) => {
          if (item === data.item.id) {
            this.selectRow.splice(index, 1)
          }
        })
        this.$refs.refFormInputListTable.unselectRow(data.index)
      }
    },
    resetImportData() {
      this.importData.data_header = []
      this.importData.form = []
      this.importData.option_label = []
      this.importData.data_records = []
      this.importData.file_count = []
      this.importData.file_total = []
      this.importData.file_name = []
    },
    onPageChanged(page) {
      this.$store.state.entriesPageDatatable = page
      // const entriesPageTable = this.$store.state.entriesPageDatatable.toString()
      // console.log(document.querySelectorAll(`button[aria-posinset="${entriesPageTable}"]`)[0].parentNode.classList)
    },
    // onFormSelect(event, index) {
    //   console.log(this.$refs.form_label[index])
    //   console.log(event)
    // },
    rowClickHandler(record) {
      this.$router.push({ name: 'leads-detail', params: { id: record.id, uuid: this.$router.currentRoute.params.uuid } })
    },
    setSelectedOption(options, value) {
      if (!value.value) {
        const option = options.find(x => { return x.value === value.label })
        return option.label
      }
      return value.label
    },
    async fetchAllLeadStatuses() {
      await store.dispatch('menu-lead/getAllLeadStatuses')
        .then(response => {
          Object.entries(response.data.data).forEach(value => {
            if (value[1].status_name === 'All') {
              this.statusFilter = value[1].id
            }
            this.statusOptions.push({ label: value[1].status_name, value: value[1].id })
            if (this.$store.state.leadStatusEntries !== 0) {
              this.statusFilter = this.$store.state.leadStatusEntries
            }
          })
        })
    },
    async fetchAllMarketingChannels() {
      await store.dispatch('menu-lead/getAllMarketingChannelsByLandingPage', { uuid: this.$route.params.uuid })
        .then(response => {
          this.channelOptions.push({ label: 'Landing Page', value: 'landing_page' })
          this.channelOptions.push({ label: 'Manual Input', value: 'manual_input' })
          this.channelOptions.push({ label: 'Bulk Input', value: 'bulk_input' })
          // this.channelOptions.push({ label: 'Embed', value: 'embed' })
          Object.entries(response.data.data).forEach(value => {
            this.channelOptions.push({ label: value[1].label, value: value[1].value })
          })
          this.channelFilter = 'all'
        })
    },
    async fetchAllSalespersons() {
      await store.dispatch('menu-lead/allSalespersonAssignedByLandingPage', { uuid: this.$route.params.uuid })
        .then(response => {
          Object.entries(response.data.data).forEach(value => {
            this.salespersonOptions.push({ label: value[1].label, value: value[1].value })
            this.assignedSalespersonOptions.push({ label: value[1].label, value: value[1].value })
          })
        })
    },
    async fetchAllSalespersonHaveLeads() {
      await store.dispatch('menu-lead/allSalespersonAssignedByLandingPageHaveLeads', { uuid: this.$route.params.uuid })
        .then(response => {
          console.log("oioioeasdiroei")
          Object.entries(response.data.data).forEach(value => {
            this.salespersonHaveLeadOptions.push({ label: value[1].label, value: value[1].value })
          })
        })
    },
    async submitImport() {
      this.bulkImportShow = true
      const newFormData = new FormData()
      if (this.importData.file_import !== null){
        newFormData.append('file_import', this.importData.file_import)
      }
      if (this.importData.import_assigned_model !== null){
        newFormData.append('import_assigned_model', this.importData.import_assigned_model)
      }
      if (this.importData.salesperson_assigned !== null){
        newFormData.append('salesperson_assigned', this.importData.salesperson_assigned)
      }
      if (this.importData.landing_page_uuid !== null){
        newFormData.append('landing_page_uuid', this.importData.landing_page_uuid)
      }
      if (this.importData.form_label !== null){
        this.importData.form_label.forEach(item => {
          if (item !== null) {
            const arraySplit = item.split('-')
            const value = arraySplit[0]
            const header = arraySplit[1]
            this.importData.input.push({ value, header })
          }
        })
        newFormData.append('inputs', JSON.stringify(this.importData.input))
      }
      await store.dispatch('menu-lead/actionImport', newFormData)
        .then(resolve => {
          this.importBtn = false
          // reset
          this.importData.salesperson_assigned = null
          this.channelFilter = 'bulk_input'
          this.channelFilter = 'all'
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Success to import leads !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: resolve.data.message,
            },
          })
        }).catch(error => {
          if (error.response !== undefined){
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Error has occured',
                icon: 'XIcon',
                variant: 'danger',
                text: error.response.data.message,
              },
            })
            this.importBtn = false
          }
        }).finally(() => {
          this.importData.input = []
        })
      this.bulkImportShow = false
    },
    async parseCSV() {
      let arrayValue = []
      let arrayLabel = []
      const newFormData = new FormData()
      if (this.importData.file_import !== null){
        newFormData.append('file_import', this.importData.file_import)
      }
      if (this.importData.landing_page_uuid !== null){
        newFormData.append('landing_page_uuid', this.importData.landing_page_uuid)
      }
      await store.dispatch('menu-lead/actionParseCSV', newFormData)
        .then(resolve => {
          this.importBtn = false
          // reset
          this.importData.data_header = resolve.data.data.headers
          this.importData.form = resolve.data.form
          this.importData.form.labels = JSON.parse(this.importData.form.labels)
          this.importData.form.values = JSON.parse(this.importData.form.values)
          // Initialize option label
          arrayValue = this.importData.form.values
          arrayLabel = this.importData.form.labels
          arrayValue.forEach((item, index) => {
            this.importData.option_label.push({ value: arrayValue[index], text: arrayLabel[index] })
          })
          this.importData.data_records = resolve.data.data.records
          this.importData.file_count = resolve.data.data.records.length
          this.importData.file_total = resolve.data.file.count
          this.importData.file_name = resolve.data.file.name
          this.$bvModal.show('modal-submit-import')
          // $bvModal
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully parsing CSV file !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: resolve.data.message,
            },
          })
        }).catch(error => {
          if (error.response !== undefined){
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Error has occurred',
                icon: 'XIcon',
                variant: 'danger',
                text: error.response.data.message,
              },
            })
            this.importBtn = false
          }
        })
    },
    async submitExport() {
      this.exportBtn = true
      this.exportData.landing_page_uuid = this.$route.params.uuid
      this.exportData.origin = this.channelFilter
      this.exportData.lead_status_id = this.statusFilter
      if (this.exportData.range_date !== null && this.exportData.range_date !== ''){
        const dateStart = this.exportData.range_date.split(' to ')[0]
        this.exportData.date_start = dateStart
        const dateEnd = this.exportData.range_date.split(' to ')[1]
        this.exportData.date_end = dateEnd
      }

      await store.dispatch('menu-lead/actionExport', this.exportData)
        .then(() => {
          this.$bvModal.hide('modal-submit-import')
          this.exportBtn = false
          this.exportData.range_date = null
          this.exportData.lead_status_id = null
        })
        .catch(error => {
          if (error.response.status === 422){
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error has occured',
                icon: 'XIcon',
                variant: 'danger',
                text: error.response.data.message,
              },
            })
          }
        })
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .row-hand-cursor {
    cursor: pointer;
  }
  .table__filter,
  .table__filter-left,
  .table__filter-right .table__filter-right-menu {
    display: flex;
  }
  .table__filter {
    align-items: end;
    justify-content: space-between;
  }
  .import-link, .export-link {
    color: #505050;
  }
  .import-link:hover, .export-link:hover {
    color: #1b4694;
  }
  @media (max-width: 960px) {
    .filter-collapse-btn {
      display: unset !important;
    }
    .filter__sidebar {
      display: none !important;
    }
    .table__filter-right .table__filter-right-menu {
      display: none !important;
    }
  }
  .table-submit-import {
    overflow-x: auto;
    display: block;
  }
  .table-submit-import table {
    border-collapse: collapse;
    width: 100%;
    height: 100%;
  }
  .table-submit-import table td, .table-submit-import table th {
    border: 1px solid #ddd;
    padding: 8px;
    width: 100%;
    white-space: nowrap;
  }
  .table-submit-import table th {
    padding-top: 12px;
    padding-bottom: 12px;text-align: left;
    background-color: #f3f2f7;
  }
</style>
