<template>
  <div class="content">
    <b-row class="match-height">
      <b-col
        xs="12"
        lg="6"
      >
        <b-card no-body>
          <b-card-header>
            <b-card-title>
              Leads Status
            </b-card-title>
          </b-card-header>

          <b-overlay
            :show="leadStatusLoading"
            variant="light"
            :opacity="0.7"
            blur="2px"
            rounded="sm"
          >
            <b-card-body style="max-height: 420px; padding-top: 0">
              <div class="font-weight-bold pb-1">
                <table>
                  <tr>
                    <td>Total Leads</td>
                    <td class="pl-1 pr-2">
                      :
                    </td>
                    <td>{{ totalLeads }}</td>
                  </tr>
                  <tr>
                    <td>Target Leads</td>
                    <td class="pl-1 pr-2">
                      :
                    </td>
                    <td>{{ landingPageData.submission_goal }}</td>
                  </tr>
                </table>
              </div>
              <!-- chart -->
              <canvas
                id="leadsStatusChart"
                height="300"
                style="max-height: 340px;"
              />
            </b-card-body>
          </b-overlay>
        </b-card>
      </b-col>
      <b-col
        xs="12"
        lg="6"
      >
        <b-card no-body>
          <b-card-header>
            <b-card-title>Top 5 Salespersons (Deal)</b-card-title>
            <!-- datepicker -->
            <!--          <div class="d-flex align-items-center">-->
            <!--            <span>select date filter first</span>-->
            <!--            <feather-icon-->
            <!--              icon="CalendarIcon"-->
            <!--              size="16"-->
            <!--            />-->
            <!--            <flat-pickr-->
            <!--              v-model="rangePicker"-->
            <!--              :config="{ mode: 'range'}"-->
            <!--              class="form-control flat-picker bg-transparent border-0 shadow-none"-->
            <!--              placeholder="YYYY-MM-DD"-->
            <!--            />-->
            <!--          </div>-->
            <!-- datepicker -->
          </b-card-header>
          <b-overlay
            :show="topSalespersonDealLoading"
            variant="light"
            :opacity="0.7"
            blur="2px"
            rounded="sm"
          >
            <!-- chart -->
            <b-card-body style="height: 420px; width: 100%; max-height: 420px;">
              <canvas
                id="topSalesChart"
                height="400"
              />
            </b-card-body>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
    <!-- Marketing Channel Conversion Rate -->
    <b-row>
      <b-col
        xs="12"
        md="12"
      >
        <b-card title="Marketing Channel Conversion Rate">
          <b-overlay
            :show="marketingChannelLoading"
            variant="light"
            :opacity="0.7"
            blur="2px"
            rounded="sm"
          >
            <template v-if="marketingChannelsConversionRate.length > 0">
              <div
                v-for="(value, key) in marketingChannelsConversionRate"
                :key="key"
                class="mb-1"
              >
                <b-progress
                  :max="maxProgressBar"
                  height="2rem"
                >
                  <b-progress-bar :value="printConversionRate(value.form_inputs_count, value.visitor_count)">
                    <!--              <span>{{ value.name }}: <strong>{{ value.form_inputs_count/value.visitor_count*100 }} %</strong></span>-->
                  </b-progress-bar>
                </b-progress>
                <div class="font-weight-bold my-1">
                  <h4>{{ value.name }}</h4>
                </div>
                <div>
                  <table>
                    <tr>
                      <td>Visit</td>
                      <td class="pl-1 pr-2">
                        :
                      </td>
                      <td>{{ value.visitor_count || 0 }}</td>
                    </tr>
                    <tr>
                      <td>Submission</td>
                      <td class="pl-1 pr-2">
                        :
                      </td>
                      <td>{{ value.form_inputs_count || 0 }}</td>
                    </tr>
                    <tr>
                      <td>Conversion Rate (%)</td>
                      <td class="pl-1 pr-2">
                        :
                      </td>
                      <td>{{ printConversionRate(value.form_inputs_count, value.visitor_count) }}</td>
                    </tr>
                  </table>
                </div>
                <hr>
              </div>
            </template>
            <div v-else>
              <span class="font-italic">No marketing channel existed</span>
            </div>
          </b-overlay>
          <!--        <b-progress height="2rem" :value="75" show-progress class="mb-2"></b-progress>-->
        </b-card>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import store from '@/store'
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BRow, BCol, BProgress, BProgressBar, BOverlay,
} from 'bootstrap-vue'
// import flatPickr from 'vue-flatpickr-component'ws/charts-and-maps/charts/chartjs/charts-components/ChartjsComponentHorizontalBarChart.vue'
import Chart from 'chart.js'
import chartjsData from './components/chartjsData'

export default {
  components: {
    // flatPickr,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BProgress,
    BProgressBar,
    BRow,
    BCol,
    BOverlay,
  },
  filters: {
    arrayToString(string, nullValue) {
      return string !== '[]' ? JSON.parse(string).toString() : nullValue
    },
  },
  props: {
    landingPageUuid: {
      type: String,
      required: true,
      default: '',
    },
    landingPageData: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      chartjsData,
      // rangePicker: [new Date((new Date()).valueOf() - 1000 * 60 * 60 * 24).toISOString().slice(0, 10), new Date().toISOString().slice(0, 10)],
      rangePicker: ['', ''],
      totalLeads: 0,
      maxProgressBar: 100,
      leadStatusLoading: true,
      topSalespersonDealLoading: false,
      marketingChannelLoading: true,
      leadStatuses: [],
      marketingChannelsConversionRate: [],
      chartTopSales: null,
      chartLeadsStatus: null,
      canvasTopSales: null,
      canvasLeadsStatus: null,
    }
  },
  watch: {
    rangePicker() {
      this.fetchTopSalespersonDeal()
    },
  },
  created(){
    this.chartjsData.latestBarChart.data.datasets[0].data = []
    this.chartjsData.latestBarChart.data.labels = []
    this.fetchCountLeadStatuses()
    this.fetchTopSalespersonDeal()
    this.fetchMarketingConversionRateByLandingPage()
  },
  methods: {
    printConversionRate(submission, visit) {
      return submission && visit ? (submission / visit) * 100 : 'N/A'
    },
    async fetchMarketingConversionRateByLandingPage() {
      this.marketingChannelLoading = true
      // get all Lead Statuses data from its landing page
      await store.dispatch('menu-lead/getMarketingConversionRateByLandingPage',
        { uuid: this.$route.params.uuid })
        .then(response => {
          this.marketingChannelsConversionRate = response.data.data
        })
      this.marketingChannelLoading = false
    },
    async fetchCountLeadStatuses() {
      this.leadStatusLoading = true
      // get all Lead Statuses data from its landing page
      await store.dispatch('menu-lead/getCountLeadStatuses', { uuid: this.$route.params.uuid })
        .then(response => {
          this.totalLeads = response.data.data.reduce((a, b) => { return a + b }, 0)
          this.chartjsData.horizontalBarChart.data.datasets[0].data = response.data.data
        })
      // get all master Lead statuses
      await store.dispatch('menu-lead/getAllLeadStatuses')
        .then(response => {
          Object.entries(response.data.data).forEach(value => {
            if (value[1].status_label !== 'all') {
              this.leadStatuses.push(value[1].status_name)
            }
          })
          this.chartjsData.horizontalBarChart.data.labels = this.leadStatuses
        })

      if (this.chartLeadsStatus != null) {
        this.chartLeadsStatus.destroy()
      }
      this.canvasLeadsStatus = document.getElementById('leadsStatusChart').getContext('2d')
      this.chartLeadsStatus = new Chart(this.canvasLeadsStatus, this.chartjsData.horizontalBarChart)
      this.chartLeadsStatus.update()
      // this.renderChart(this.chartjsData.horizontalBarChart.data, chartjsData.horizontalBarChart.options)
      this.leadStatusLoading = false
    },
    async fetchTopSalespersonDeal() {
      this.topSalespersonDealLoading = true
      // let dateArray = null
      // if (this.rangePicker.includes(' to ')) {
      //   dateArray = this.rangePicker.split(' to ')
      // }
      let paramRequest = null

      // if (dateArray instanceof Array) {
      //   if (dateArray[1] !== undefined) {
      //     paramRequest = {
      //       uuid: this.$route.params.uuid,
      //       top: 5,
      //       start_limit_date: dateArray[0],
      //       end_limit_date: dateArray[1],
      //     }
      //   }
      // } else {
      //   paramRequest = {
      //     uuid: this.$route.params.uuid,
      //     top: 5,
      //   }
      // }
      paramRequest = {
        uuid: this.$route.params.uuid,
        top: 5,
      }

      await store.dispatch('menu-lead/getTopSalespersonDeal', paramRequest)
        .then(response => {
          // this.chartjsData.latestBarChart.options.scales.yAxes[0].ticks.max = Math.max(...response.data.data.deals)
          this.chartjsData.latestBarChart.data.datasets[0].data = response.data.data.deals
          this.chartjsData.latestBarChart.data.labels = response.data.data.name
        })

      if (this.chartTopSales != null) {
        this.chartTopSales.destroy()
      }
      this.canvasTopSales = document.getElementById('topSalesChart').getContext('2d')
      this.chartTopSales = new Chart(this.canvasTopSales, this.chartjsData.latestBarChart)
      this.chartTopSales.update()
      this.topSalespersonDealLoading = false
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
