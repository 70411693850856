import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { formatDate } from '@core/utils/filter'
import { useRouter } from '@core/utils/utils'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useFormInputDetails() {
  // Use toast
  const toast = useToast()
  const { route, router } = useRouter()
  const refFormInputListTable = ref(null)

  // Table Handlers
  const tableColumns = []
  const tableKeys = []
  const perPage = ref(25)
  const totalForms = ref(null)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const salespersonFilter = ref(0)
  const channelFilter = ref(null)
  const dateFilter = ref(null)
  const hotleadFilter = ref(null)
  const statusFilter = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refFormInputListTable.value ? refFormInputListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalForms.value,
    }
  })

  const refetchData = () => {
    refFormInputListTable.value.refresh()
  }

  const clearDateFilter = () => {
    dateFilter.value = ''
  }

  watch([currentPage, perPage, searchQuery, salespersonFilter, channelFilter, hotleadFilter, statusFilter, dateFilter], () => {
    refetchData()
  })

  const getFormResults = (ctx, callback) => {
    store
      .dispatch('menu-lead/getFormResults', {
        search: searchQuery.value,
        perPage: perPage.value,
        page: store.state.entriesPageDatatable,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        salesperson: salespersonFilter.value,
        origin: channelFilter.value,
        hotlead: hotleadFilter.value,
        created_at: dateFilter.value,
        lead_status: statusFilter.value,
        uuid: route.value.params.uuid,
        user_id: store.state.currentUser.id,
        role_code: store.state.currentUser.role.code,
      })
      .then(response => {
        const { data, total } = response.data.data

        if (data[0]?.form_result !== undefined){
          let columnIndex = 0
          tableColumns.length = 0

          tableColumns.unshift({
            key: 'selected',
            label: '',
          })
          Object.entries(data[0].form_result).forEach(value => {
            // limit showing first 3 column
            if (columnIndex < 3){
              const keyString = (`form_result.${String(value[0])}`)
              tableColumns.push({ key: keyString, label: value[0].replace(/_/g, ' '), sortable: false })
              tableKeys.push(value[0])
              columnIndex += 1
            }
          })
          tableColumns.push({
            key: 'lead_status.status_name',
            label: 'Status',
            sortable: true,
          })
          tableColumns.push({
            key: 'created_at',
            label: 'Created At',
            formatter: formatDate,
            sortable: true,
          })
        }

        // trim tag html
        Object.entries(data).forEach(value => {
          const form = value[1].form_result
          Object.keys(form).forEach(key => {
            if (Object.hasOwnProperty.call(form, key)) {
              /* eslint-disable */
              form[key] = form[key]?.toString().replace(/^\&nbsp\;|<br?\>*/gi, ' ').replace(/\&nbsp\;|<br?\>$/gi, ' ').trim()
              /* eslint-enable */

              // Convert object array to string
              if (typeof form[key] === 'object' && form[key]) form[key].join(', ')
            }
          })
        })
        callback(data)
        totalForms.value = total
      })
      .catch(error => {
        if (error.response.status === 403) {
          router.push({ name: 'error-404' })
        }
        toast({
          component: ToastificationContent,
          props: {
            title: error,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    getFormResults,
    tableColumns,
    tableKeys,
    perPage,
    currentPage,
    totalForms,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refFormInputListTable,
    refetchData,
    clearDateFilter,

    // Extra Filters
    salespersonFilter,
    channelFilter,
    hotleadFilter,
    statusFilter,
    dateFilter,
  }
}
