<template>
  <div>
    <!-- faq search section -->
    <!--    <section id="faq-search-filter">-->
    <!--      <b-card-->
    <!--        no-body-->
    <!--        class="landing-page-header"-->
    <!--        :style="{backgroundImage:`url(${require('@/assets/images/banner/banner.png')})`}"-->
    <!--      >-->
    <!--        <b-card-body class="text-center">-->
    <!--          <h2 class="text-primary">-->
    <!--            {{ landingPageData.title }}-->
    <!--          </h2>-->
    <!--          <b-card-text class="mb-2">-->
    <!--            {{ landingPageData.description }}-->
    <!--          </b-card-text>-->
    <!--        </b-card-body>-->
    <!--      </b-card>-->
    <!--    </section>-->

    <!-- frequently asked questions tabs pills -->
    <div class="mb-2">
      <h2>{{ this.landingPageData.title }}</h2>
    </div>
    <section
      v-if="leadShow === true"
      id="faq-tabs"
    >
      <b-card>
        <b-tabs class="col-12 pl-0">
          <!-- overview tab -->
          <b-tab
            :active="this.$store.state.leadTabs === 'overview'"
            @click="changeTab('overview')"
          >
            <template #title>
              <span class="font-weight-bold">Overview</span>
            </template>

            <overview-component
              :landing-page-data="this.landingPageData"
              :landing-page-uuid="$route.params.uuid"
            />
          </b-tab>
          <!-- entries tab -->
          <b-tab
            :active="this.$store.state.leadTabs === 'entries'"
            @click="changeTab('entries')"
          >
            <!-- title -->
            <template #title>
              <span class="font-weight-bold">Entries</span>
            </template>

            <entries-component :landing-page-uuid="$route.params.uuid" />
          </b-tab>
          <!-- Salesperson tab -->
          <b-tab
            v-if="this.$store.state.currentUser.role.code === 'su'"
            :active="this.$store.state.leadTabs === 'salesperson'"
            @click="changeTab('salesperson')"
          >
            <!-- title -->
            <template #title>
              <span class="font-weight-bold">Salesperson</span>
            </template>

            <salesperson-component
              :landing-page-uuid="$route.params.uuid"
              :total-landing-page="this.landingPages.length"
            />
          </b-tab>

          <!-- sitting lady image -->
          <!--        <template #tabs-end>-->
          <!--          <b-img-->
          <!--            fluid-->
          <!--            :src="require('@/assets/images/illustration/faq-illustrations.svg')"-->
          <!--            class="d-none d-md-block mt-auto"-->
          <!--          />-->
          <!--        </template>-->
          <!--/ sitting lady image -->
        </b-tabs>
      </b-card>
    </section>
    <empty-page
      v-else
      :empty-kind-page="'lead'"
      :header-string="'You do not have active Leads yet'"
      :content-string="'Make sure you have a Landing Page first.'"
      :link-to-name="'leads-create'"
    />
  </div>
</template>

<script>
import store from '@/store'
import {
  BCard, BTabs, BTab,
} from 'bootstrap-vue'
import EmptyPage from '@/views/menus/EmptyPage.vue'
import EntriesComponent from './EntriesComponent.vue'
import OverviewComponent from './OverviewComponent.vue'
import SalespersonComponent from './SalespersonComponent.vue'

export default {
  components: {
    OverviewComponent,
    EntriesComponent,
    SalespersonComponent,
    EmptyPage,
    BTabs,
    BTab,
    BCard,
  },
  data() {
    return {
      faqSearchQuery: '',
      faqData: {},
      landingPageData: {
        picture_link: null,
        description: null,
        title: null,
        form_id: null,
      },
      formId: 0,
      leadShow: true,
      landingPages: [],
    }
  },
  watch: {
    faqSearchQuery: {
      immediate: true,
      handler() {
        this.fetchData()
      },
    },
    // landingPageData: {
    //   immediate: true,
    //   handler() {
    //     console.log(this.$route.meta.breadcrumb)
    //     this.$route.meta.breadcrumb.forEach((item, index) => {
    //       if (item.unique === 'uuid'){
    //         this.$route.meta.breadcrumb[index].text = this.landingPageData.title
    //       }
    //     })
    //   },
    // },
  },
  created() {
    this.fetchData()
    this.fetchLandingPages()
    if (this.$store.state.leadTabs === ''){
      this.$store.state.leadTabs = 'overview'
    }
    this.landingPages.push({ label: 'All Landing Page', value: 'all' })
  },
  methods: {
    fetchData() {
      if (this.$route.params.uuid !== undefined){
        store.dispatch('menu-landing-page/getLandingPageFormByUuid', { uuid: this.$route.params.uuid })
          .then(response => {
            // resolve Landing Page data
            this.landingPageData = response.data.data
            this.formId = this.landingPageData.form_id
          })
          .catch(error => {
            if (error.response.status !== 200){
              this.leadShow = false
            }
          })
      }
    },
    async fetchLandingPages() {
      await store.dispatch('menu-lead/getAllLandingPages', {
        user_id: this.$store.state.currentUser.id,
      })
        .then(response => {
          response.data.forEach(item => {
            this.landingPages.push({ label: item.label, value: item.uuid })
            // if (item.uuid === this.$route.params.uuid) {
            //   this.landingPageSelect = { label: item.label, value: item.uuid }
            // }
          })
        })
    },
    changeTab(tabStr) {
      // this.$store.commit('leadTabs', 'overview')
      this.$store.state.leadTabs = tabStr
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>
