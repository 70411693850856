import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useSalespersonsList() {
  // Use toast
  const toast = useToast()

  const refSalespersonListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'user', sortable: true },
    // { key: 'lead_list', label: 'In Lead List', sortable: false },
    // { key: 'total_handle', label: 'Total Handle', sortable: false },
    // { key: 'conv_rate', label: 'Conversion Rate', sortable: false },
    { key: 'total_new', label: 'New', sortable: false },
    { key: 'total_process', label: 'On Process', sortable: false },
    { key: 'total_visit', label: 'Visit', sortable: false },
    { key: 'total_deal', label: 'Deal', sortable: false },
    { key: 'total_fail', label: 'Fail', sortable: false },
    { key: 'total_handle', label: 'Total Lead', sortable: false },
    { key: 'last_added', label: 'Last Added', sortable: false },
    { key: 'actions', label: '' },
  ]
  const perPage = ref(25)
  const totalSalespersons = ref(null)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refSalespersonListTable.value ? refSalespersonListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalSalespersons.value,
    }
  })

  const refetchData = () => {
    refSalespersonListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const getSalesperson = (ctx, callback) => {
    store
      .dispatch('menu-lead/getSalespersons', {
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: 'salesperson',
        plan: planFilter.value,
        status: true,
        landing_page_uuid: router.currentRoute.params.uuid,
        user_id: store.state.currentUser.id,
      })
      .then(response => {
        const { data, total } = response.data.data

        callback(data)
        totalSalespersons.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'Super Admin') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'Super User') return 'success'
    if (role === 'Salesperson') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  return {
    getSalesperson,
    tableColumns,
    perPage,
    currentPage,
    totalSalespersons,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refSalespersonListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
